<template>
  <v-container fluid>
    <v-layout column>
      <v-flex xs12>
        <v-card class="subheading pa-3 mt-3">
          <div class="title ma-2" style="display: flex;">
            <span style="margin-top:10px;">Reported Student List</span>&nbsp;&nbsp;&nbsp;
            <v-divider class="my-6" vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
            style="margin-top:-14px; margin-bottom:-14px;"
            type="number"
            v-model="reg_no"
            label="Search With Reg No"
          ></v-text-field>
          </div>
          <v-data-table
            :headers="headers"
            :items="filteredReportedData"
            class="elevation-1"
            :loading="loading"
            hide-actions
          >
            <template v-slot:items="{ item, index }">
              <tr>
                <td class="text-xs-center">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.reg_no }}</td>
                <td class="text-xs-center">{{ item.exam_roll_no }}</td>
                <td class="text-xs-left">{{ item.reg_info.name }}</td>
                <td class="text-xs-left">
                  <v-text-field
                    v-model="item.reported_years"
                    :disabled="!item.is_edit"
                    label="Reported Years"
                  ></v-text-field>
                </td>
                <td class="text-xs-center">
                  <v-btn
                    v-if="!item.is_edit"
                    fab
                    small
                    dark
                    color="#3F51B5"
                    @click="editItem(item)"
                  >
                    <v-icon color="#fff">edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.is_edit"
                    fab
                    small
                    dark
                    color="green"
                    @click="saveItem(item)"
                  >
                    <v-icon color="#fff">save</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
        reg_no: '',
      loading: false,
      searchParam: {},
      dialog: false,
      headers: [
        { text: "Sl", value: "id" },
        { text: "Reg No", value: "reg_no" },
        { text: "Exam Roll", value: "exam_roll_no" },
        { text: "Name", value: "name" },
        { text: "Reported Years", value: "" },
        { text: "Action", value: "action" },
      ],
      items_status: [
        { key: "Correction", value: "correction" },
        { key: "New", value: "new" },
      ],
    };
  },
  computed: {
    getReportedData() {
      return this.$store.getters.getReportedStudentData;
    },
    filteredReportedData() {
      if (this.reg_no) {
        return this.getReportedData.filter(item => 
          item.reg_no.toString().includes(this.reg_no.toString())
        );
      }
      return this.getReportedData;
    },
  },
  created() {
    this.getResultDataSet();
  },
  methods: {
    editItem(item) {
      console.log(item);
      item.is_edit = true;
    },
    saveItem(item) {
      console.log("Saving item:", item);
      let pearm = {};
      pearm.id = item.id;
      pearm.reported_years = item.reported_years;
      this.$store.dispatch("updateReportedStudent", pearm).then(() => {
        
      });
      item.is_edit = false;
    },
    getResultDataSet() {
      this.loading = true;
      let param = {
        exam_initiate_id: this.getExamInitiateId,
        exam_code: this.getLoggedInExam.code,
        exam_year: this.getLoggedInYear.year,
      };
      this.$store.dispatch("fetchReportedStudent", param).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
